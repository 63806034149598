// gatsby-browser.js

import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import ThemeProvider from './src/ThemeContext';
import 'devicon/devicon.min.css'; 
import './src/styles/global'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      {(theme) => (
        <StyledThemeProvider theme={theme}>
          {element}
        </StyledThemeProvider>
      )}
    </ThemeProvider>
  );
};
