import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'devicon';
    src: 
      url('/fonts/devicon.woff2') format('woff2'),
      url('/fonts/devicon.woff') format('woff'),
      url('/fonts/devicon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  i[class^="devicon-"], i[class*=" devicon-"] {
    font-family: 'devicon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  :root {
    --background: ${({ theme }) => theme.colors.background};
    --secondary-background: ${({ theme }) => theme.colors.secondaryBackground};
    --primary-text: ${({ theme }) => theme.colors.primaryText};
    --secondary-text: ${({ theme }) => theme.colors.secondaryText};
    --accent: ${({ theme }) => theme.colors.accent};
    --accent-hover: ${({ theme }) => theme.colors.accentHover};
    --nav-active: ${({ theme }) => theme.colors.navActive};
    --nav-icon: ${({ theme }) => theme.colors.navIcon};
    --nav-hover: ${({ theme }) => theme.colors.navHover};
    --nav-hover-background: ${({ theme }) => theme.colors.navHoverBackground};
    --nav-focus-background: ${({ theme }) => theme.colors.navFocusBackground};
    --nav-active-indicator: ${({ theme }) => theme.colors.navActiveIndicator};
    --nav-icon-hover: ${({ theme }) => theme.colors.navIconHover};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: var(--background);
    color: var(--primary-text);
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: var(--primary-text);
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    color: var(--secondary-text);
  }

  a {
    color: var(--accent);
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--accent-hover);
    }
  }

  img {
    max-width: 100%;
    display: block;
  }

  button {
    font-family: inherit;
    cursor: pointer;
    background: none;
    border: none;
  }

  ul {
    list-style: none;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
`;

export default GlobalStyle;
